// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import TextLoop from 'react-text-loop'
// import { Link } from 'gatsby'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import Video from '../components/Video'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Why Us?',
  nakedPageSlug: 'why-us',
  pageAbstract: 'Content being added at the moment.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 style={{ marginBottom: 0 }}>Why Us?</h1>
    <h2 className="mask-h4" style={{ marginBottom: 60 }}>
      Experience&nbsp;
      <TextLoop>
        <span style={{ color: '#004195' }}>
          <i>An Award Winning School</i>
        </span>
        <span style={{ color: '#004195' }}>
          <i>Quality Education</i>
        </span>
        <span style={{ color: '#004195' }}>
          <i>Care</i>
        </span>
      </TextLoop>
    </h2>
    <Row gutter={{ xs: 12, sm: 18, md: 24, xl: 36 }}>
      <Col sm={24} md={12}>
        <Video
          url="https://youtu.be/GVgvFjZFfTc"
          style={{ marginBottom: 30 }}
        />
      </Col>
      <Col sm={24} md={12}>
        <Video
          url="https://www.youtube.com/watch?v=HJSpSzc2MQ0"
          style={{ marginBottom: 30 }}
        />
      </Col>
    </Row>
    <h2>Teachers</h2>
    <p>
      Cambridge teachers are competent as they are well equipped with the latest
      techniques and methods of teaching learning process. They take up the
      challenging tasks inside and outside the classroom and help the students
      to gain confidence and overcome the obstacles in their learning process.
      The communal hard work of the teachers acts as a driving force by which
      the goals and objectives of teaching learning process is achieved.
    </p>
    <h2>Modern Classrooms</h2>
    <p>
      Cambridges' Modern Classroom is a productive environment in which students
      can develop the skills they will require in the workplace and teachers are
      facilitators of their learning. The focus of these classrooms is on
      students, experiencing the environment, they will enter as modern day
      workers and developing their higher order thinking skills, effective
      communication skills, collaboration skills, making them adapt with using
      technology and all other skills in their day to day life.
    </p>
    <h2>Innovative Pedagogy</h2>
    <p>
      Cambridge believes in providing education in real sense. It is a temple of
      learning, where students are taught how to think and not what to think.
      Its interactive teaching - learning pedagogy has drawn appreciation from
      all quarters. Uniqueness of Cambridge lies in providing wholesome
      education to the students. It is an institution, where paramount
      importance is given to weaving life skills like self awareness, critical
      thinking, creative thinking, coping with stress, empathy, decision making,
      problem solving and effective communication in the curriculum. A
      challenging platform is provided to the students, so that they are able to
      explore themselves and prove themselves true to the motto of the school,
      that is, Dream, Dare and Do. We, at Cambridge, value their creative
      expression and encourage their unique abilities.
    </p>
    <h2>Special Educational Needs</h2>
    <p>
      Cambridge Special Needs Program aims to improve through inclusion,
      education and advocacy, the quality of life of differently abled children.
      Learning Wings is an international community of professionals committed to
      meeting the diverse needs of children and creating opportunities for
      students with special educational needs in the mainstream. Our program’s
      vision is to reach out to all learners and enable them to receive
      meaningful and equitable access to the curriculum. It is our goal that
      each student, receive the necessary resources, guidance, accommodations,
      and differentiation to attain their highest level of personal success. We
      value collaboration and shared responsibility with students, parents and
      community partners to create a culture of inclusivity, compassion and
      respect for individual differences.
    </p>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
